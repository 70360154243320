import React from "react"
import { Image, StyleSheet, View } from "react-native"

import { Heading, player_color, space, useNormalize } from "@unboared/base-ui.all"
import { GAME_ID, LOGO_GAME } from "../../../../config"
import { ScrollingHighscores } from "../../../highscores/v1/scrolling_highscores"
import { useHighscores } from "../../../../services/db/highscores"
import PositionScore from "../../../highscores/v1/score/PositionScore"

export const RightSide = () => {
    const { normalize } = useNormalize()
    const styles = makeStyles(normalize)

    return (
        <View style={styles.container}>
            <View style={{
                flex: 0.15,
                alignSelf: 'stretch',
            }}>
                <Image
                    source={{ uri: LOGO_GAME }}
                    style={{
                        height: '100%',
                        resizeMode: 'contain',
                    }}
                />
            </View>
            <View style={styles.ranking}>
                <Heading type="h3" style={styles.title} text="Gloofy Pop Rank" />
                <LeaderBoard />
            </View>
        </View>
    )
}

const LeaderBoard = () => {
    /* Get highscores from the database */
    const { normalize } = useNormalize()
    const highscores = useHighscores(GAME_ID, 100);

    if (!highscores) {
        return <></>;
    }
    return (
        <View style={{ flex:1}}>
            {
                highscores.length > 0 &&
                <PositionScore
                    position={1}
                    player={highscores[0]}
                    score={highscores[0].score}
                    color={player_color[0]}
                    size={normalize(20)}
                />
            }
            {
                highscores.length > 1 &&
                <PositionScore
                    position={2}
                    player={highscores[1]}
                    score={highscores[1].score}
                    color={player_color[2]}
                    size={normalize(20)}
                />
            }
            {
                highscores.length > 2 &&
                <PositionScore
                    position={3}
                    player={highscores[2]}
                    score={highscores[2].score}
                    color={player_color[3]}
                    size={normalize(20)}
                />
            }
            <ScrollingHighscores highscores={highscores} />
        </View>
    )
}

const makeStyles = (normalize: (x: number) => number) => StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: 'stretch',
        paddingTop: normalize(space.large),
        paddingHorizontal: normalize(space.large),
        justifyContent: 'center',
    },
    or: { fontWeight: 'bold', fontSize: normalize(16) },
    title: { alignSelf: "center", paddingBottom: normalize(space.small) },
    ranking: { flex: 0.85, marginTop: normalize(space.medium) },
})