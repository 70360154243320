/* METADATA */
export const GAME_NAME = 'AirportsDemo';
export const DEFAULT_SCENE = 'connexion';
export const CLIENT_ID = 'airports-demo';
export const GAME_ID = 'gloofy-pop';
export const DEPLOY_URL = "airports.unboared.com"
export const SCREEN_URL = "https://gloofy-pop-airports.web.app";
export const GAMEPAD_URL = "https://gloofy-pop-airports.web.app/gamepad.html";
export const DEBUG = false
export const CODE_SESSION_LENGTH = 6
export const LOGO_GAME = "https://developers.unboared.com/public-assets/images/games/gloofy-pop/Logo.png"

/* SOCKET SERVER */
export const PROD_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com/'; 

/**
 * Add '/gamepad' at the end of the url. 
 * If the url is 'http://my-screen.com', then this function 
 * will return 'http://my-screen.com/gamepad'
 * @param baseURL the base url
 * @returns the default gamepad url
 */
export const computeDefaultGamepadURL = (baseURL: string) => {
  return baseURL + (baseURL.charAt(baseURL.length - 1) !== '/' ? '/' : '') + 'gamepad';
}

/**
 * Gets the gamepad URL.
 * @param gameURL the URL of the game to be run
 * @param host the host
 * @param sessionID the sessionID
 * @param wss the websocket server location
 * @returns the gamepad url
 */
export const getGamepadURL = (
  gameURL: string,
  host: string,
  sessionID: number | string,
  wss?: string,
  version?: string
) =>
  `${host}/#/gamepad/${sessionID}?url=${encodeURIComponent(
    `${gameURL}`
  )}${wss ? `&wss=${encodeURIComponent(wss)}` : ''}${version ? `&version=${encodeURIComponent(version)}` : ''}`;

/**
 * Gets the gamepad URL format.
 * @param gameURL the URL of the game to be run
 * @param host the host
 * @param wss the websocket server location
 * @returns the gamepad url format
 */
export const getGamepadURLFormat = (
  gameURL: string,
  host: string,
  wss?: string,
  version?: string
) => {
  return getGamepadURL(gameURL, host, '{sessionID}', wss, version);
};

// Your web app's Firebase configuration
export const firebaseConfig = {

  apiKey: "AIzaSyBKtC41EuAQIJLUZ6HSE0AoWvfwOb8ef2Q",

  authDomain: "unboared-b2b.firebaseapp.com",

  projectId: "unboared-b2b",

  storageBucket: "unboared-b2b.appspot.com",

  messagingSenderId: "334911476740",

  appId: "1:334911476740:web:a191a626c9a466322e9151"

};

export function create_UUID(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}
