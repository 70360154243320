import React from "react"
import { StyleSheet, View } from "react-native"

import { unboaredTheme } from "@unboared/base-ui.all"
import { LeftSide } from "./LeftSide"
import { RightSide } from "./RightSide"

export const ThreeSideLayout = ({ children }: any) => {
    return (
        <View style={styles.container}>
            <View style={styles.side}>
                <LeftSide />
            </View>
            <View style={styles.gameView}>
                {children}
            </View>
            <View style={styles.side}>
                <RightSide />
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: unboaredTheme.dark.backgroundColor,
        flexDirection: 'row'
    },
    side: {
        flex: 0.25,
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    gameView: {
        flex: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
    }
})