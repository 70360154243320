import React from "react"
import { ProfileSetting } from "../../components/profile_settings"
import { View } from "react-native"
import { base_color } from "@unboared/base-ui.all"

export const ScreenProfileSetting = ({onSubmitted}:any) => {

    return (
        <View style={{ flex: 1, alignSelf: 'stretch', backgroundColor: base_color.dark }}>
            <ProfileSetting onSubmitted={onSubmitted} />
        </View>
    )
}