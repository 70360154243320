import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useTranslate, space, useNormalize, accent_color } from '@unboared/base-ui.all';
import { Text, Heading, Button, PortraitMobileLayout } from '@unboared/base-ui.all';
import { CreateAvatar } from './components/CreateAvatar';
import { CreateUsername } from './components/CreateUsername';

export function makeid(length: number) {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const getAdventurerURL = (seed: string) => {
    return `https://api.dicebear.com/5.x/adventurer/png?seed=${seed}&backgroundColor=transparent`;
};

export const sampleAvatar = (): string => {
    const seed = makeid(8);
    return getAdventurerURL(seed);
};

export type ProfileSettingProps = {
    player?: any;
    onSubmitted?: (player: any) => void;
};

export const ProfileSetting = ({
    player = { username: "", avatar: "" },
    onSubmitted,
}: ProfileSettingProps) => {

    // Use theme and translation
    const { translate } = useTranslate();

    // Create styles
    const { normalize } = useNormalize();
    const styles = makeStyles(normalize)

    // Current local avatar
    const [localAvatar, setLocalAvatar] = useState<string>(player.avatar || sampleAvatar());

    // The username field value
    const [localUsername, setLocalUsername] = useState(player.username);

    // The error message
    const [error, setError] = useState('');

    const handleSubmit = () => {       
            if (onSubmitted) {
                onSubmitted({ ...player, username: localUsername, avatar: localAvatar });
            }
    };

    const input_styles = [styles.input, { marginBottom: normalize(space.small) }];

    return (
        <View style={{ flex: 1, alignSelf: 'stretch' }}>
            <PortraitMobileLayout
                header={<Heading>{translate('gamepad.profileSetting.title')}</Heading>}
                flex={{ header: 20, content: 80 }}
            >
                <View style={input_styles}>
                    <CreateAvatar value={localAvatar} onChange={setLocalAvatar} generator={sampleAvatar} size={80} />
                </View>
                <View style={input_styles}>
                    <CreateUsername value={localUsername} onChange={setLocalUsername} />
                </View>
                <View style={[input_styles, styles.buttons]}>
                    <Button
                        preset="primary"
                        text={translate('gamepad.profileSetting.create.submitText')}
                        onPress={handleSubmit}
                        style={{ flex: 0.8 }}
                    />
                </View>
                {error && <Text style={{ color: accent_color.error }} text={error} />}
            </PortraitMobileLayout>
        </View>
    );
};

const makeStyles = (normalize: (x: number) => number) => StyleSheet.create({
    input: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttons: {
        flexDirection: 'row',
        width: '80%',
        justifyContent: 'space-evenly',
    },
    codeContainer: {
        width: '90%',
        marginTop: normalize(space.smaller),
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    textInputContainer: {
        borderWidth: 2,
        marginLeft: normalize(space.tiny),
        minWidth: '20%',
        maxWidth: '60%'
    }
});
