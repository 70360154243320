import React from "react"
import { GameRunner } from "../../components/game_runner"
import { SCREEN_URL } from "../../config"
import { TwoSideLayout } from "../../components/layouts/screen_layouts/TwoSideLayout"
import { ThreeSideLayout } from "../../components/layouts/screen_layouts/ThreeSideLayout"

export const ScreenManager = () => (
    <ThreeSideLayout>
        <GameRunner
            title="Gloofy Pop"
            src={SCREEN_URL}
            version="0.4"
        />
    </ThreeSideLayout>
)
