import { useRef, useState } from "react";

/**
 * Hooks that help managing vertical scroll for ScrollView.
 */
export const useVerticalScroll = () => {
    const scrollRef = useRef();
    const [offset, setOffset] = useState(0);

    /* Scroll vertically to a specific offset */
    const scrollTo = (y: number) => {
        if (scrollRef.current != null) {
            scrollRef.current.scrollTo({ x: 0, y, animated: true });
            setOffset(y);
        }
    };

    /* Scroll down */
    const scrollDown = (px: number) => {
        scrollTo(offset + px)
    };

    /* Scroll top */
    const scrollTop = (px: number) => scrollDown(-px)

    /* Go to the top of the ScrollView */
    const restart = () => {
        scrollTo(0)
    };

    return { scrollRef, offset, scrollDown, scrollTop, restart }
}