import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db, endpoints } from '../config';

import { HighscoresAPI } from './HighscoresAPI';
import { CLIENT_ID } from '../../../config';

export const useHighscores = (gameID?: string, limit: number = -1) => {
    const [highscores, setHighscores] = useState();

    const loadHighscores = (gameID: string, limit: number) => {
        HighscoresAPI.retrieveHighscores(CLIENT_ID, gameID, limit)
            .then((result) => {
                setHighscores(result);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const highscoresListener = (gameID: string, limit: number) => {
        const docRef = collection(db, "clients", CLIENT_ID, 'games', gameID, 'highscores');

        const unsub = onSnapshot(docRef, (doc) => {
            loadHighscores(gameID, limit);
        });

        return unsub;
    }

    useEffect(() => {
        if (gameID) {
            // listen to highscore changes
            const unsub = highscoresListener(gameID, limit)

            // get highscores
            loadHighscores(gameID, limit);
            return unsub;
        }
    }, [gameID, limit]);

    return highscores;
};
