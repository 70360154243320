import React from 'react'

import GamepadApp from './gamepad_app' // the gamepad application
import HomeApp from './home_app'
import { ThemeProvider, unboaredTheme } from '@unboared/base-ui.all'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ScreenAccess } from './screen/ScreenAccess'

const AppStack = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="app" element={<HomeApp />} />
        <Route path="gamepad/:sessionID" element={<GamepadApp />} />
        <Route path="*" element={<ScreenAccess />} />
      </Routes>
    </BrowserRouter>
  )
}


export default function App() {
  return (
    <ThemeProvider theme={unboaredTheme.dark}>
      <AppStack />
    </ThemeProvider>
  )
}
