import React from "react"
import { StyleSheet, View, ViewBase, ViewStyle } from "react-native"

import { useSession } from "@unboared/lib"

import { Heading, Text, accent_color, size, space, useNormalize } from "@unboared/base-ui.all"
import { Logo, QRCodeView, RoomIDView } from "@unboared/design.all"
import { DEPLOY_URL } from "../../../../config"

export const LeftSide = () => {
    const { getSessionID, getSessionLink } = useSession();

    const { normalize } = useNormalize()
    const styles = makeStyles(normalize)
    const flexCenter = (value: number) => ({ flex: value, justifyContent: 'center', alignItems: 'center' } as ViewStyle)

    return (
        <View style={styles.container}>
            <View style={flexCenter(0.15)}>
                <Logo size={30} />
            </View>
            <View style={[flexCenter(0.85), { marginTop: normalize(space.medium), justifyContent: 'space-evenly' }]}>
                <View style={styles.connexion}>
                    <Heading type="h3" style={styles.title} text="Join the game!" />
                    <QRCodeView roomID={getSessionID()} url={getSessionLink()} size={120} />
                </View>
                <View style={{ width: '80%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                    <View style={styles.orDelimiter}></View>
                    <Text style={styles.or} text="OR" />
                    <View style={styles.orDelimiter}></View>
                </View>
                <View style={styles.connexion}>
                    <Text style={styles.text}>Open <Text style={{ fontWeight: 'bold', color: accent_color.important }}>{DEPLOY_URL}</Text> in your browser</Text>
                    <Text style={styles.text}>Enter the code below:</Text>
                    <RoomIDView roomID={getSessionID()} url={getSessionLink()} size={16} test />
                </View>
            </View>
        </View>
    )
}

const makeStyles = (normalize: (x: number) => number) => StyleSheet.create({
    container: {
        flex: 1,
        alignSelf: 'stretch',
        paddingHorizontal: normalize(space.large),
        paddingVertical: normalize(space.small),
    },
    or: { marginHorizontal: normalize(space.small), fontWeight: 'bold', fontSize: normalize(16) },
    orDelimiter: { flex: 1, borderColor: '#FFF', borderBottomWidth: normalize(size.borderWidth.tiny) },
    title: { alignSelf: "center", paddingBottom: normalize(space.small) },
    connexion: { alignItems: 'center', justifyContent: 'flex-start' },
    text: { fontWeight: 'bold', textAlign: 'center', marginVertical: normalize(space.smaller) }
})