import React from 'react'
import { Platform } from 'react-native'

import { UnboaredGame, CommunicationConfig } from '@unboared/lib'
import { DEFAULT_SCENE, GAME_NAME, GameRunnerApi, PROD_SOCKET_SERVER } from './config'
import { LoadingPage } from './components/loading_page'
import { ScreenManager } from './screen/ScreenManager'

export const ROOM_ENDPOINT = '/gamepad/';

var host =
  Platform.OS === 'web'
    ? window.location.protocol + '//' + window.location.host
    : 'https://play.unboared.com/';



export const getRoomURL = (host: string, room: number | string) => {
  return host + ROOM_ENDPOINT + room;
};


const HomeApp = () => {
  return (
    <UnboaredGame
      name={GAME_NAME}
      api={new GameRunnerApi(DEFAULT_SCENE, 'screen', PROD_SOCKET_SERVER).init()}
      initialScene={DEFAULT_SCENE}
      loader={<LoadingPage infos="Connexion to the server..." />}
      config={CommunicationConfig.getSocketIO('screen', PROD_SOCKET_SERVER, {
        url: getRoomURL(host, '{sessionID}'),
      })}
    >
      <ScreenManager />
    </UnboaredGame >
  )
}

export default HomeApp;