import { db, endpoints } from "../config";
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";

/**
 * Get highscore related to a document
 * @param docHighscore the document
 * @returns 
 */
const getHighscore = async (docHighscore: any) => {
    let user;
    try {
        user = docHighscore.data();
    } catch (err) {
        console.error(err);
        throw 'Cannot get user infos for highscores .';
    }
    return user;
};

/**
 * Convert snapshot into a list of highscores  
 * @param querySnapshot 
 * @returns 
 */
const getHighscores = async (querySnapshot: any) => {
    let hsPromises: any[] = [];
    querySnapshot.forEach((doc) => {
        hsPromises.push(getHighscore(doc));
    });
    return await Promise.all(hsPromises);
};

/** */
async function retrieveHighscores(clientID: string, gameID: string, total: number) {
    let limitValue = total <= 0 ? 10000 : total;

    let scoresRef = collection(db, "clients", clientID, 'games', gameID, 'highscores');
    const q = query(scoresRef, orderBy('score', 'desc'), orderBy('date'), limit(limitValue));
    let highscores;
    try {
        let querySnapshot = await getDocs(q);
        highscores = await getHighscores(querySnapshot);
    } catch (err) {
        console.error('Cannot get highscores.');
        console.error(err);
    }

    return highscores;
}

function saveScore(clientID: string, gameID: string, uid: string, score: number, data: any) {
    let highscoreRef = doc(db, "clients", clientID, 'games', gameID, 'highscores', uid);

    let newScore = {
        ...data,
        uid: uid,
        date: serverTimestamp(),
        score: score,

    }
    getDoc(highscoreRef)
        .then((doc: any) => {
            if (doc.exists()) {
                let highscore = doc.data();
                if (highscore.score < score) {
                    updateDoc(highscoreRef, newScore).catch((err) => {
                        console.error("Cannot update highscore.")
                        console.error(err)
                    })
                }
            }
            else {
                setDoc(highscoreRef, newScore).catch((err) => {
                    console.error("Cannot create highscore.")
                    console.error(err)
                })
            }
            // this.unboaredEventManager.dispatch('onHighscoreSaved', { gameID, deviceID, level })
        })
        .catch((err) => {
            console.error("Cannot get highscore.")
            console.error(err)
        });
}

export const HighscoresAPI = {
    saveScore: saveScore,
    retrieveHighscores: retrieveHighscores,
}