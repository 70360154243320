import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native'
import { useParams } from 'react-router-dom';

import { CommunicationConfig, UnboaredGame, useScene } from '@unboared/lib'
import { useGamepadScale, useNormalize, space } from '@unboared/base-ui.all'
import { FullscreenButton as FullscreenButtonComponent } from '@unboared/design.all'
import { toggleFullscreen, useFullscreen } from '@unboared/utils.fullscreen'

import { LoadingPage } from './components/loading_page'

import { DEFAULT_SCENE, GAMEPAD_URL, GAME_NAME, PROD_SOCKET_SERVER, create_UUID } from './config/Const';
import { useConnectionStatus } from './components/connection_status';
import { GameRunner } from './components/game_runner';
import { ScreenProfileSetting } from './screen/ScreenProfile';
import { useLocalAccount, useLocalAccounts } from './components/local_account';

export const FullscreenButton = ({ size = 40 }: { size?: number }) => {
  const { isFullscreen, toggleFullscreen } = useFullscreen()

  return (
    <FullscreenButtonComponent
      size={size}
      onPress={toggleFullscreen}
      active={isFullscreen}
    />
  )
}

export default () => {
  useGamepadScale();
  return <CreateGamepadSignIn />;
};

export const CreateGamepadSignIn = () => {
  const [launched, setLaunched] = useState(false)
  const { normalize } = useNormalize()
  const { isFullscreen, toggleFullscreen } = useFullscreen();


  // const { accounts, addAccount } = useLocalAccounts()
  // 
  const [currentPlayer, setCurrentPlayer] = useState<any>();

  const urlParams = useParams();

  const launchUI = (newPlayer: any) => {
    setLaunched(true);
    setCurrentPlayer({ ...newPlayer, uid: create_UUID() });
    if (!isFullscreen) {
      toggleFullscreen()
    }
  };

  if (!launched) {
    return <ScreenProfileSetting onSubmitted={launchUI} />;
  }

  return (
    <UnboaredGame
      name={GAME_NAME}
      initialScene={DEFAULT_SCENE}
      loader={<LoadingPage infos="Connexion to the server..." />}
      config={CommunicationConfig.getSocketIO(
        'gamepad', PROD_SOCKET_SERVER,
        {
          id: urlParams.sessionID,
        },
        currentPlayer
      )}
    >
      <GamepadSceneManager />
      {Platform.OS == 'web' && (
        <View
          style={{
            position: 'absolute',
            top: normalize(space.smaller),
            right: normalize(space.smaller),
            zIndex: 99,
          }}
        >
          <FullscreenButton size={20} />
        </View>
      )}
    </UnboaredGame>
  );
};

export const GamepadSceneManager = () => {
  const scene = useScene().getScene();
  const screenConnected = useConnectionStatus();

  if (!screenConnected) {
    return <LoadingPage infos="Bad internet connection" />;
  }

  if (!scene) {
    return <LoadingPage infos="Loading parameters..." />;
  }

  return <GameRunner title="Gloofy Pop" src={GAMEPAD_URL} version="0.4" />
};
