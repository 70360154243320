import React, { useRef, useEffect, useCallback, useState } from 'react';

import { useTranslate, FullPageOverlay } from '@unboared/base-ui.all';
import { useLanguage } from '@unboared/utils.language';
import { api, useListener, usePlayers, useCommunication } from '@unboared/lib';
import { UnboaredActivityIndicator } from '@unboared/design.all';

export const GameRunner = ({ title, src }: { title: string; src: string }) => {
  const iframeRef = useRef(null);
  const language = useLanguage((state) => state.language);
  const currentScene = useRef('home');
  const { translate } = useTranslate();
  const [gameLoaded, setGameLoaded] = useState(false);

  const {
    getMasterID,
    getUID,
    getColor,
    getUsername,
    getAvatar,
    isScreen,
    getPlayer,
    getDeviceID,
    getPlayers,
  } = usePlayers();

  const type = isScreen() ? 'screen' : 'gamepad';
  const me = {
    uid: getUID(),
    username: getUsername(),
    avatar: getAvatar(),
    color: getColor(),
  };

  const { send, emitAction, broadcast } = useCommunication();

  // Envoie un message au Iframe sous-jacent
  // -- ex : { }
  const postToWebView = useCallback(
    (message: any) => {
      if (!iframeRef.current) return;
      iframeRef.current.contentWindow.postMessage(message, src);
    },
    [iframeRef]
  );

  // Recois les messages du Iframe sous-jacent
  // -- ex : { }
  useEffect(() => {
    const handler = (e: any) => {
      // console.log(`# New message from ${e.origin}`);
      // console.log(`--> ${JSON.stringify(e.data)}`);
      let data = e.data;
      // if (e.origin !== src) return;
      if (data.action === '__onReady__') {
        setGameLoaded(true);
        postToWebView({
          name: '__onReady__',
          players: getPlayers(),
          me: me,
          masterID: api().getUID(api().getMasterID()),
          scenario: {
            game: title,
            scenario: 'home',
            scenarioComponent: undefined,
          },
          type: type,
          roomID: api().getSessionID(),
          language: language,
        });
        emitAction('__askChildState__', { from: getDeviceID() });
      } else if (data.action === 'send') {
        if (data.deviceID !== '') {
          if (data.name == '__set__') {
            broadcast('__setGlobalState__', data);
          } else {
            send(data['deviceID'], '__message__', data);
          }
        } else {
          emitAction('__message__', data);
        }
      } else if (data.action === 'sendServer') {
        if (data.name === 'changeScenario') {
          broadcast('__changeWebviewScenario__', {
            game: data.game,
            scenario: data.scenario,
          });
        }
        // sendServer(data.name, data);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ecoute les messages arrivant des gamepads et les transfert au jeu sous-jacent
  // -- ex : { }
  useListener(
    '__message__',
    (data) => {
      postToWebView(data);
    },
    []
  );

  useListener(
    '__setGlobalState__',
    (data: { game: string; scenario: string }) => {
      if (!api().isScreen(api().getDeviceID())) {
        postToWebView({
          name: '__set__',
          ...data,
        });
      }
    }
  );

  useListener('__childState__', (data: any) => {
    postToWebView({
      name: 'changeScenario',
      game: title,
      scenario: data.scene,
    });
  });

  useListener('__askChildState__', (data: any) => {
    send(data.from, '__childState__', { scene: currentScene.current });
  });

  useListener(
    '__changeWebviewScenario__',
    (data: { game: string; scenario: string }) => {
      if (!api().isScreen(api().getDeviceID())) {
        postToWebView({
          name: 'changeScenario',
          ...data,
        });
      } else {
        currentScene.current = data.scenario;
      }
    },
    [currentScene]
  );

  useEffect(() => {
    const unsubscribe = api().onConnect((deviceID: string) => {
      postToWebView({
        name: '__onConnect__',
        uid: api().getUID(deviceID),
        username: api().getUsername(deviceID),
        avatar: api().getAvatar(deviceID),
        color: api().getColor(deviceID),
      });
      postToWebView({
        name: '__onMasterID__',
        masterID: api().getUID(api().getMasterID()),
      });
    });
    return unsubscribe
  }, []);

  useEffect(() => {
    const unsubscribe = api().onDisconnect((deviceID: string) => {
      postToWebView({
        name: '__onDisconnect__',
        deviceID: deviceID,
        masterID: api().getUID(api().getMasterID()),
      });
    });
    return unsubscribe
  }, []);

  return (
    <>
      <FullPageOverlay active={!gameLoaded}>
        <UnboaredActivityIndicator size={60} infos={translate('screen.loading.gameIFrame')} />
      </FullPageOverlay>
      <iframe
        ref={iframeRef}
        title={title}
        src={src}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </>
  );
};
