import { Player } from "@unboared/lib"

const createPlayer = (username: string, avatar: string): Player => {
    const uuid = Math.floor(Math.random() * 100000000)

    return { uid: `${uuid}`, username, avatar, color: '' }
}


export const UsersForTest = [
        // createPlayer("Marion", "https://api.dicebear.com/5.x/adventurer/png?seed=Bubbax"),
        createPlayer("Marion", "https://api.dicebear.com/5.x/adventurer/png?seed=claudine"),
        createPlayer("Remina", "https://api.dicebear.com/5.x/adventurer/png?seed=dzdefrgthyo"),
        createPlayer("Dorian", "https://api.dicebear.com/5.x/adventurer/png?seed=Abbyu"),
        createPlayer("Mark", "https://api.dicebear.com/5.x/adventurer/png?seed=11111"),
        createPlayer("Nolwenn", "https://api.dicebear.com/5.x/adventurer/png?seed=Unboard"),
        createPlayer("Bob", "https://api.dicebear.com/5.x/adventurer/png?seed=333"),
        createPlayer("Geraldine", "https://api.dicebear.com/5.x/adventurer/png?seed=12dsqd"),
        createPlayer("Nelly", "https://api.dicebear.com/5.x/adventurer/png?seed=1"),
        createPlayer("Claudine", "https://api.dicebear.com/5.x/adventurer/png?seed=SugaBGP"),
        // createPlayer("Gus", "https://api.dicebear.com/5.x/adventurer/png?seed=pobn"),
]