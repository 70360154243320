import { useEffect, useState } from 'react';
import { api, usePlayers, onDisconnect, onConnect } from '@unboared/lib';

export const useConnectionStatus = () => {
  const { getDeviceID } = usePlayers();
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const unsubscribe = onDisconnect(api, (deviceID: string) => {
      if (deviceID === getDeviceID()) {
        setIsConnected(false);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = onConnect(api, (deviceID: string) => {
      if (deviceID === getDeviceID()) {
        setIsConnected(true);
      }
    });
    return unsubscribe;
  }, []);

  return isConnected;
};
