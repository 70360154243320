import React from "react"
import { Heading, accent_color, space, useNormalize, useTranslate } from "@unboared/base-ui.all"
import { AvatarSelection } from "@unboared/design.all"

export const CreateAvatar = ({ value, onChange, generator, size }: any) => {
    const { normalize } = useNormalize()
    const { translate } = useTranslate()

    return (
        <>
            <Heading type="h3" style={{ margin: normalize(space.smaller) }}>
                {translate('gamepad.profileSetting.avatarLabel')}
            </Heading>
            <AvatarSelection
                current={value}
                setAvatar={onChange}
                color={accent_color.important}
                generator={generator}
                size={size}
            />
        </>
    )
}