import React from 'react';
import { View } from 'react-native';
import { Theme, useTheme } from '@unboared/base-ui.all';
import { UnboaredActivityIndicator } from '@unboared/design.all';

export const LoadingPage = ({ infos, size = 80 }: any) => {
  const theme = useTheme() as Theme;

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor,
      }}
    >
      <UnboaredActivityIndicator size={size} infos={infos} />
    </View>
  );
};
