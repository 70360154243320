import React, { useState } from "react"
import { Image, Platform, View } from "react-native"
import { Button, Heading, Text, TextInput, TextInputWithButton, accent_color, base_color, space, useNormalize, useTranslate } from "@unboared/base-ui.all"
import { CODE_SESSION_LENGTH, LOGO_GAME } from "../../config"

var host =
    Platform.OS === 'web'
        ? window.location.protocol + '//' + window.location.host
        : 'https://play.unboared.com/';


export const ScreenAccess = ({ onSubmitted }: any) => {

    const { normalize } = useNormalize();

    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const checkCode = (code: string) => {
        let isnum = /^\d+$/.test(code);
        return (isnum && code.length === CODE_SESSION_LENGTH)
    }

    const submit = (code: string) => {
        if (checkCode(code)) {
            window.open(`${host}/gamepad/${code}`, '_self');
        }
        else {
            setErrorMessage("Wrong session code.")
        }
    };

    const onChange = (newText: string) => {
        setCode(newText);
        setErrorMessage("")
    };

    return (
        <View style={{ flex: 1, alignSelf: 'stretch', backgroundColor: base_color.dark }}>

            <View style={{
                flex: 0.25,
                alignSelf: 'stretch',
                padding: normalize(space.large)
            }}>
                <Image
                    source={{ uri: LOGO_GAME }}
                    style={{
                        height: '100%',
                        resizeMode: 'contain',
                    }}
                />
            </View>
            <View style={{ flex: 1, justifyContent: 'center', }}>

                <View
                    style={{
                        alignItems: 'center',
                        paddingVertical:normalize(space.large)
                    }}
                >
                    <Heading tx="gamepad.joinPage.title" />
                    {errorMessage &&
                        <Text style={{ color: accent_color.warning }} text={errorMessage} />
                    }
                </View>

                <View style={{ alignItems: 'center', paddingVertical:normalize(space.large)}}>
                    <Heading type="h2" tx="gamepad.joinPage.codeSession.help" />
                    <TextInput
                        text={code}
                        containerStyle={{ marginVertical: normalize(space.small) }}
                        style={{ fontSize: normalize(15) }}
                        // label="Session Code"
                        minLength={6}
                        maxLength={6}
                        placeholder={'0'.repeat(CODE_SESSION_LENGTH)}
                        onChangeText={onChange}
                        keyboardType="numeric"
                    // helperText={translate('gamepad.joinPage.codeSession.help')}
                    />
                    <Button textPreset="medium" tx="gamepad.joinPage.submit" onPress={() => submit(code)} size={15} />
                </View>
            </View>
        </View>

    );
};
