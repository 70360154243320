// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

import { firebaseConfig } from '../../config';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

const clientEndpoint = (clientID: string) => {
    return `/clients/${clientID}`;
}

const gameEndpoint = (clientID: string, gameID: string) => {
    return `${clientEndpoint(clientID)}/games/${gameID}`;
}

const highscoresEndpoint = (clientID: string, gameID: string) => {
    return `${gameEndpoint(clientID, gameID)}/highscores`;
}

export const endpoints = {
    client: (clientID: string) => ({
        value: clientEndpoint(clientID),
        game: (gameID: string) => ({
            value: gameEndpoint(clientID, gameID),
        }),
        highscores: (gameID: string) => ({
            value: highscoresEndpoint(clientID, gameID)
        })
    }),
}

export { db }