import { SocketIoApi } from '@unboared/lib'
import { UsersForTest } from './UsersForTest'
import { HighscoresAPI } from '../services/db/highscores/HighscoresAPI'
import { CLIENT_ID, GAME_ID } from './Const'

export const HIGHSCORES = [
    {
        username: UsersForTest[0].username,
        avatar: UsersForTest[0].avatar,
        score: 18,
        date: new Date(2022, 8, 22),
        position: 1,
    },
    {
        username: UsersForTest[1].username,
        avatar: UsersForTest[1].avatar,
        score: 17,
        date: new Date(2023, 1, 10),
        position: 2,
    },
    {
        username: UsersForTest[2].username,
        avatar: UsersForTest[2].avatar,
        score: 12,
        date: new Date(2023, 2, 10),
        position: 3,
    },
    {
        username: UsersForTest[3].username,
        avatar: UsersForTest[3].avatar,
        score: 12,
        date: new Date(2022, 10, 14),
        position: 4,
    },
    {
        username: UsersForTest[4].username,
        avatar: UsersForTest[4].avatar,
        score: 10,
        date: new Date(2023, 3, 3),
        position: 5,
    },
    {
        username: UsersForTest[5].username,
        avatar: UsersForTest[5].avatar,
        score: 9,
        date: new Date(2022, 11, 3),
        position: 6,
    },
    {
        username: UsersForTest[6].username,
        avatar: UsersForTest[6].avatar,
        score: 9,
        date: new Date(2022, 5, 30),
        position: 7,
    },
    {
        username: UsersForTest[7].username,
        avatar: UsersForTest[7].avatar,
        score: 9,
        date: new Date(2022, 1, 13),
        position: 8,
    },
    {
        username: UsersForTest[8].username,
        avatar: UsersForTest[8].avatar,
        score: 7,
        date: new Date(2022, 6, 23),
        position: 9,
    }
]

export class GameRunnerApi extends SocketIoApi {

    /**
     * Create a new webview api interface
     */
    constructor(initialScene: string, deviceType: 'gamepad' | 'screen', server: string) {
        super(initialScene, deviceType, server)
    }

    /**
     * Browse to the url.
     * @param url the target url
     * @screen
    */
    browseToURL(url: string): void {
        console.log(`Browse to url - ${url}`)
    }

    /**
     * Browse to the home page of the parent app.
     * @screen
    */
    browseToHome(): void {
        console.log(`Browse to home`)
    }

    /**
     * This function saves a persistent data in the database 
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {string} key the key 
     * @param {any} data the data to persist
     */
    savePersistentData(gameID: string, deviceID: string, key: string, data: any): void {
        this.unboaredEventManager.dispatch('onPersistentDataSaved', { gameID, deviceID, key, data })
    }

    /**
     * Retrieve a persistent data 
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {string} key the key 
     */
    retrievePersistentData(gameID: string, deviceID: string, key: string): void {
        this.unboaredEventManager.dispatch('onPersistentDataRetrieve', { gameID, deviceID, key, data: { data1: "data1", data2: 20 } })
    }


    /**
     * This function saves highscore for a player
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {number} score the score to be saved 
     * @param {string|undefined} level the level identifier
     */
    saveHighscore(gameID: string, deviceID: string, score: number, level?: string): void {
        let uid = this.getUID(deviceID);
        HighscoresAPI.saveScore(CLIENT_ID, GAME_ID, uid, score, {
            avatar: this.getAvatar(deviceID),
            username: this.getUsername(deviceID),
        });
    }

    /**
     * This function retrieves a highscore for a specific player in the database 
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {string|undefined} level the level identifier
     */
    retrieveHighscore(gameID: string, deviceID: string, level?: string): void {
        console.log("Undefined function")
    }

    retrieveHighscores(gameID: string, total: number, group?: string | Array<string>, level?: string) {
        HighscoresAPI.retrieveHighscores(CLIENT_ID, GAME_ID, total).then((highscores) => {
            this.unboaredEventManager.dispatch('onHighscoresRetrieve', {
                gameID, group, highscores: highscores, level
            })
        }).catch((err) => {
            console.error('Cannot get highscores.');
            console.error(err);
        })
    }

}