import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Text,
  size as scaleSize,
  space,
  transparency,
  useNormalize,
} from '@unboared/base-ui.all';
import { Avatar } from '@unboared/design.all';

import { ScorePositionProps } from './podium/PodiumScore';

/**
 * Displays the highscore of a player rankek after the third position. 
 */
const PositionScore = (props: ScorePositionProps) => {
  /* Get properties */
  const { style, position, player, color, score, size } = props

  /* Create styles */
  const { normalize } = useNormalize();
  const styles = positionScoreStyles(normalize)

  return (
    <View style={[styles.container, { backgroundColor: color }]}>
      {/* The position of the player */}
      <View style={styles.positionContainer}>
        <Text style={styles.bigNumber} text={`${position}`} />
      </View>
      {/* The informations about the player */}
      <Avatar name={player.avatar} color="" size={size} />
      <Text
        preset="large"
        style={styles.username}
        text={`${player.username}`}
        numberOfLines={1}
        ellipsizeMode='tail'
      />
      {/* The score of the player */}
      <View style={styles.scoreContainer} >
        <Text style={styles.bigNumber} text={`${score}`} numberOfLines={1} />
      </View>
    </View>
  );
};


/* Stylesheet */
const positionScoreStyles = (normalize: (x: number) => number) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: transparency('dark', 0.1),
      borderRadius: 20,
      marginVertical: normalize(space.tiny),
      width: '100%',
    },
    positionContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '15%',
      borderColor: transparency('dark', 0.1),
      borderRightWidth: normalize(scaleSize.borderWidth.medium),
    },
    scoreContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '25%',
      borderColor: transparency('dark', 0.1),
      borderLeftWidth: normalize(scaleSize.borderWidth.medium),
    },
    username: {
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'center',
      fontWeight: 'bold',
      fontSize: normalize(15)
    },
    bigNumber: {
      fontFamily: 'Arco',
      fontSize: normalize(12)
    }
  });


export default PositionScore;
