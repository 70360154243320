import React from "react"
import { Heading, TextInput, space, useNormalize, useTranslate } from "@unboared/base-ui.all"

export const CreateUsername = ({ value, onChange }: any) => {
    const { normalize } = useNormalize()
    const { translate } = useTranslate()

    return (
        <>
            <Heading type="h3" style={{ margin: normalize(space.smaller) }}>
                {translate('gamepad.profileSetting.create.usernameLabel')}
            </Heading>
            <TextInput
                minLength={3}
                maxLength={12}
                text={value}
                placeholder={translate(
                    'gamepad.profileSetting.usernamePlaceholder',
                )}
                onChangeText={onChange}
            />
        </>
    )
}