import MainApp from './src/app'

import { i18n } from '@unboared/base-ui.all';
import { en, fr } from "./src/translations"
import { useFonts } from 'expo-font'

i18n.translations = { en, fr }

export default function App() {

  // Load fonts
  const [fontsLoaded] = useFonts({
    Arco: require('./src/assets/fonts/ARCO.ttf'),
  })

  if (!fontsLoaded) {
    return null
  }


  return <MainApp />
}
